/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import $ from 'jquery';
import 'bootstrap'; 
import 'jquery.cookie';
 
/**
 * Opt in (e.g. EU):
 * default all scripts disabled.
 * cookie banner
 *
 * Opt out (e.g. US):
 * default all scripts enabled
 * opt out cookie banner
 *
 * Other regions:
 * default all scripts enabled
 * no banner
 *
 *
 * For examples to edit the behaviour of the banner, please see https://github.com/really-Simple-Plugins/banner_cookies-integrations
 * */
 
/* COOKIES */

//temp
let c = document.cookie.split(";").reduce( (ac, cv, i) => Object.assign(ac, {[cv.split('=')[0]]: cv.split('=')[1]}), {});

$('.list_actions a').click(function(e) {
	e.preventDefault();
	$('#cookies__wrapper').modal('hide');
});

if(($.cookie('banner_marketing') === undefined) && ($.cookie('banner_preferences') === undefined) && ($.cookie('banner_statistics') === undefined) ) {
	$('#cookies__wrapper').modal('show');
}
 
// Politique de cookies
$('.politiques_cook').on('click', function (e) {
	window.open($(this).data('href'), '_blank');
});

$('.gray_bg').on('click', function (e) {
	$('#cookies__preferences').modal('show');
});

'use strict';
/**
 * Opt in (e.g. EU):
 * default all scripts disabled.
 * cookie banner
 *
 * Opt out (e.g. US):
 * default all scripts enabled
 * opt out cookie banner
 *
 * Other regions:
 * default all scripts enabled
 * no banner
 *
 *
 * For examples to edit the behaviour of the banner, please see https://github.com/really-Simple-Plugins/banner_cookies-integrations
 * */
var banner_cookies = {"prefix":"banner_","user_banner_id":"1","set_cookies":[],"block_ajax_content":"0","banner_version":"27","version":"6.2.4","store_consent":"","do_not_track":"","consenttype":"optin","region":"eu","geoip":"","dismiss_timeout":"","disable_cookiebanner":"","soft_cookiewall":"1","dismiss_on_scroll":"","cookie_expiry":"365","locale":"lang=fr&locale=fr_FR","set_cookies_on_root":"0","cookie_domain":"","current_policy_id":"19","cookie_path":"\/","categories":{"statistics":"statistiques","marketing":"marketing"},"tcf_active":"","placeholdertext":"<div class=\"banner-blocked-content-notice-body\">Cliquez sur \u00ab\u00a0J\u2019accepte\u00a0\u00bb pour activer {service}\u00a0<div class=\"banner-links\"><a href=\"#\" class=\"banner-link cookie-statement\">{title}<\/a><\/div><\/div><button class=\"banner-accept-service\">J\u2019accepte<\/button>","css_file":"","page_links":{"eu":{"cookie-statement":{"title":"Politique de Cookies ","url":"./politique-de-cookies\/"}}},"tm_categories":"","forceEnableStats":"","preview":"","clean_cookies":"1"};
'use strict';

/**
 * Create an element
 * @param el
 * @param content
 * @returns {*}
 */
function banner_create_element(el, content) {
	let obj = document.createElement(el);
	obj.innerHtml = content;
	return obj;
}

/**
 * Add an event
 * @param event
 * @param selector
 * @param callback
 * @param context
 */
function banner_add_event(event, selector, callback ) {
	document.addEventListener(event, e => {
		if ( e.target.closest(selector) ) {
			callback(e);
		}
	});
}

function banner_html_decode(input) {
	var doc = new DOMParser().parseFromString(input, "text/html");
	return doc.documentElement.textContent;
}
/**
 * If an anchor is passed for an element which may load only after an ajax call, make sure it will scroll into view.
 */
document.addEventListener('banner_manage_consent_container_loaded', function(e){
	let url = window.location.href;
	if ( url.indexOf('#') != -1 ) {
		let end_pos = url.lastIndexOf("?") != -1 ? url.lastIndexOf("?") : undefined;
		var anchor = url.substring(url.indexOf("#") + 1, end_pos);
		const element = document.getElementById(anchor);
		if (element) {
			const y = element.getBoundingClientRect().top + window.pageYOffset - 200;
			window.scrollTo({top: y, behavior: 'smooth'});
		}
	}
});

/**
 * prevent caching of the WP Rest API by varnish or other caching tools
 */
banner_cookies.locale = banner_cookies.locale + '&token='+Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);

/**
 * CustomEvent() polyfill
 * https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent#Polyfill
 */
(function () {
	if (typeof window.CustomEvent === 'function') return false;
	function CustomEvent(event, params) {
		params = params || { bubbles: false, cancelable: false, detail: undefined };
		var evt = document.createEvent('CustomEvent');
		evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
		return evt;
	}
	CustomEvent.prototype = window.Event.prototype;
	window.CustomEvent = CustomEvent;
})();

let banner_banner;//look this one up when the cookiebanner loads.
let banner_banner_container = document.getElementById('banner-cookiebanner-container');
let banner_manage_consent_button;
let banner_waiting_inline_scripts = [];
let banner_waiting_scripts = [];
let banner_fired_scripts = [];
let banner_placeholder_class_index = 0;
let banner_all_scripts_hook_fired = false;
let banner_consent_stored_once = false;
let banner_categories = [
	'functional',
	'preferences',
	'statistics',
	'marketing',
];

/**
 * Get a cookie by name
 * @param name
 * @returns {string}
 */

window.banner_get_cookie = function(name) {
	if (typeof document === 'undefined') {
		return '';
	}
	name = banner_cookies.prefix+name + "=";
	let cArr = document.cookie.split(';');
	for (let i = 0; i < cArr.length; i++) {
		let c = cArr[i].trim();
		if (c.indexOf(name) == 0)
			return c.substring(name.length, c.length);
	}

	return "";
}
/**
 * Get a cookie by name
 * @param name
 * @returns {string}
 */
 function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}
function setCookie(name,value){
let date = new Date();
	let secure = ";secure";
	date.setTime(date.getTime() + (banner_cookies.cookie_expiry * 24 * 60 * 60 * 1000));
	let expires = ";expires=" + date.toGMTString();
   document.cookie =  name +"="+value+";SameSite=Lax" + secure + expires +";path=/";

}
/**
 * set a cookie
 * @param name
 * @param value
 * @param use_prefix
 */

window.banner_set_cookie = function(name, value, use_prefix) {
	if (typeof document === 'undefined') {
		return;
	}
	if (typeof use_prefix === 'undefined') {
		use_prefix = true;
	}
	let secure = ";secure";
	let date = new Date();
	date.setTime(date.getTime() + (banner_cookies.cookie_expiry * 24 * 60 * 60 * 1000));
	let expires = ";expires=" + date.toGMTString();

	if (window.location.protocol !== "https:") secure = '';

	let domain = banner_get_cookie_domain();
	if (domain.length > 0) {
		domain = ";domain=" + domain;
	}
	let prefix = '';
	if ( use_prefix ) {
		prefix = banner_cookies.prefix;
	}
	document.cookie = prefix+name + "=" + value + ";SameSite=Lax" + secure + expires + domain + ";path="+banner_get_cookie_path();
}

/**
 * Check if needle occurs in the haystack
 * @param needle
 * @param haystack
 * @returns {boolean}
 */
window.banner_in_array = function(needle, haystack) {
	let length = haystack.length;
	for(let i = 0; i < length; i++) {
		if(haystack[i] == needle) return true;
	}
	return false;
}

/**
 * Retrieve the highest level of consent that has been given
 *
 * */

window.banner_highest_accepted_category = function() {
	var consentedCategories = banner_accepted_categories();
 
	if (banner_in_array( 'marketing', consentedCategories )) {
		return 'marketing';
	}

	if (banner_in_array( 'statistics', consentedCategories )) {
		return 'statistics';
	}

	if (banner_in_array( 'preferences', consentedCategories )) {
		return 'preferences';
	}

	return 'functional';
}



/**
 * Sets all accepted categories as class in body
 */

function banner_set_category_as_body_class() {
	let classList = document.body.className.split(/\s+/);
	for (let i = 0; i < classList.length; i++) {
		if ( classList[i].indexOf('banner-') !== -1 && classList[i] !== 'banner-document' ) {
			document.body.classList.remove( classList[i] );
		}
	}

	let cats = banner_accepted_categories();
	for (let i in cats) {
		if ( cats.hasOwnProperty(i) ) {
			document.body.classList.add('banner-' + cats[i]);
		}
	}

	let services = banner_get_all_service_consents();
	for (let service in services) {
		if ( services.hasOwnProperty(service) && services[service]) {
			document.body.classList.add('banner-' + service);
		}
	}

	document.body.classList.add('banner-' + banner_cookies.region);
	document.body.classList.add('banner-' + banner_cookies.consenttype);
	let event = new CustomEvent('banner_set_category_as_bodyclass');
	document.dispatchEvent(event);
}


/**
 * Enable scripts that were blocked
 *
 * */

function banner_enable_category(category, service) {

	if ( banner_cookies.tm_categories == 1 && category !== '') {
		banner_run_tm_event(category);
	}

	service = typeof service !== 'undefined' ? service : 'do_not_match';
	if ( category === '' ) category = 'do_not_match';

	if ( category === 'functional' ) {
		return;
	}

	//enable cookies for integrations
	if ( category === 'marketing' ) {
		banner_set_integrations_cookies();
	}

	//remove accept cookie notice overlay
	let selector;
	if (service !== 'do_not_match') {
		selector = '.banner-blocked-content-notice [data-service='+service+']';
	} else {
		selector = banner_cookies.clean_cookies!=1 ? '.banner-blocked-content-notice.banner-accept-'+category : '.banner-blocked-content-notice [data-category='+category+']';
	}

	

	document.querySelectorAll('[data-category='+category+'], [data-service='+service+']').forEach(obj => {
		//if a category is activated, but this specific service is denied, skip.
		let elementService = obj.getAttribute('data-service');
		if ( banner_is_service_denied(elementService) ) {
			return;
		}

		//if native class is included, it isn't blocked, so will have run already
		if ( obj.getAttribute('data-category') === 'functional' ) {
			return;
		}

		if ( obj.classList.contains('banner-activated') ) {
			return;
		}

		

		let details = {};
		details.category = category;
		details.service = service;
		let event = new CustomEvent('banner_category_enabled', { detail: details });
		document.dispatchEvent(event);
	});

	/**
	 * Let's activate the scripts
	 */

	//create list of waiting scripts
	let scriptElements = document.querySelectorAll('script[data-category='+category+'], script[data-service='+service+']');
	scriptElements.forEach(obj => {
		let waitfor = obj.getAttribute('data-waitfor');
		let src = obj.getAttribute('src');
		if ( waitfor ) {
			if ( src ) {
				banner_waiting_scripts[waitfor] = src;
			} else if ( obj.innerText.length > 0 ) {
				banner_waiting_inline_scripts[waitfor] = obj;
			}
		}

		//cleanup after adding it to our waiting or scriptElements list.
		if (obj.parentElement) {
			obj.parentElement.removeChild(obj);
		}
	});

	//scripts: remove text/plain
	scriptElements.forEach(obj => {
		//we don't want already activate scripts to fire, but also we don't want scripts that weren't blocked to fire. Hence the check for type
		let script_mime_type = obj.getAttribute('type');
		if ( obj.classList.contains('banner-activated') || ( !script_mime_type || script_mime_type==='text/javascript' ) ) {
			return;
		}
		obj.classList.add('banner-activated' );
		let src = obj.getAttribute('src');
		if ( src ) {
			obj.removeAttribute('type');
			//check if this src or txt is in a waiting script. If so, skip.
			if ( banner_is_waiting_script(banner_waiting_scripts, src) ) {
				return;
			}

			if ( obj.getAttribute('data-post_scribe_id') ) {
				let psID = '#' + obj.getAttribute('data-post_scribe_id');
				let postScribeObj = document.querySelector(psID);
				if ( postScribeObj ) {
					postScribeObj.innerHtml('');
					postscribe(psID, '<script src=' + src + '></script>');
				}
			} else {
				banner_run_script(src, category, 'src' );
			}

		} else if (obj.innerText.length > 0 ) {
			//check if this src or txt is in a waiting script. If so, skip.
			if (banner_is_waiting_script(banner_waiting_inline_scripts, obj.innerText )) {
				return;
			}

			banner_run_script( obj.innerText, category, 'inline' );
		}
	});

	//fire an event so custom scripts can hook into this.
	let details = {};
	details.category = category;
	details.service = service;
	details.categories = banner_accepted_categories();
	details.services = banner_get_all_service_consents();
	details.region = banner_cookies.region;
	let event = new CustomEvent('banner_enable_category', { detail: details });
	document.dispatchEvent(event);
	
}



/**
 * check if the passed source has a waiting script that should be executed, and return it if so.
 * @param waiting_scripts
 * @param src
 * @returns {*}
 */

function banner_get_waiting_script( waiting_scripts, src ) {
	for ( let waitfor in waiting_scripts ) {
		if ( waiting_scripts.hasOwnProperty(waitfor)) {
			if ( src.indexOf(waitfor) !== -1 ) {
				let output = waiting_scripts[waitfor];
				delete waiting_scripts[waitfor];
				return output;
			}
		}
	}

	return false;
}

/**
 * Because we need a key=>value array in javascript, the .length check for an empty array doesn't work.
 * @param arr
 * @returns {boolean}
 */
function banner_array_is_empty(arr) {
	for (let key in arr) {
		if (arr.hasOwnProperty(key)) {
			return false;
		}
	}

	return true;
}

/**
 * Check if the passed src or script is waiting for another script and should not execute
 * @param waiting_scripts
 * @param srcOrScript
 */

function banner_is_waiting_script(waiting_scripts, srcOrScript) {
	for (let waitfor in waiting_scripts) {
		if ( waiting_scripts.hasOwnProperty(waitfor) ) {
			let waitingScript = waiting_scripts[waitfor];
			if (typeof waitingScript !== 'string') waitingScript = waitingScript.innerText;

			if (srcOrScript.indexOf(waitingScript) !== -1 || waitingScript.indexOf(srcOrScript) !== -1) {
				return true;
			}
		}
	}
	return false;
}

/**
 * if all scripts have been executed, fire a hook.
 */

function banner_run_after_all_scripts(category) {
	if (!banner_all_scripts_hook_fired && banner_array_is_empty(banner_waiting_inline_scripts) && banner_array_is_empty(banner_waiting_scripts) ) {
		let event = new CustomEvent('banner_run_after_all_scripts', { detail: category });
		document.dispatchEvent(event);
		banner_all_scripts_hook_fired = true;
	}
}

/**
 * Fire an event in Tag Manager
 *
 *
 * */

let banner_fired_events = [];
function banner_run_tm_event(category) {
	if (banner_fired_events.indexOf(category) === -1) {
		banner_fired_events.push(category);
		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({
			'event': banner_cookies.prefix+'event_'+category
		});
		let event = new CustomEvent('banner_tag_manager_event', { detail: category });
		document.dispatchEvent(event);
	}
}

/**
 * Function to handle backward compatibility
 *
 */

/*function banner_legacy(){
	let has_recaptcha = false;
	document.querySelectorAll('[data-service=recaptcha]').forEach(obj => {
		obj.setAttribute('data-service', 'google-recaptcha');
		has_recaptcha=true;
	});

	if ( has_recaptcha ) {
		console.log('recaptcha as service name is deprecated. Please rename the service in your custom html to google-recaptcha');
		document.body.classList.add( 'banner-google-recaptcha' );
	}
}*/

window.conditionally_show_banner = function() {


	//banner_legacy();

	/**
	 * Integration with WordPress, tell what kind of consent type we're using, then fire an event
	 */

	window.wp_consent_type = banner_cookies.consenttype;
	let event = new CustomEvent('wp_consent_type_defined');
	document.dispatchEvent( event );
	event = new CustomEvent('banner_before_cookiebanner' );
	document.dispatchEvent(event);
	if ( banner_cookies.forceEnableStats == 1 && banner_cookies.consenttype === 'optin' ) {
		banner_set_consent('statistics', 'allow');
	}

	let rev_cats = banner_categories.reverse();
	for (let key in rev_cats) {
		if ( rev_cats.hasOwnProperty(key) ) {
			let category = banner_categories[key];
			if (banner_has_consent(category)) {
				banner_enable_category(category);
			}
		}
	}

	if ( banner_exists_service_consent() ) {
		//if any service is enabled, allow the general services also, because some services are partially 'general'
		banner_enable_category('', 'general');
		let services = banner_get_services_on_page();
		for (let key in services) {
			if ( services.hasOwnProperty(key) ) {
				let service = services[key].service;
				let category = services[key].category;
				if ( banner_has_service_consent( service, category )) {
					document.querySelectorAll('.banner-accept-service[data-service=' + service + ']').forEach(obj => {
						obj.checked = true;
					});
					banner_enable_category('', service);
				}
			}
		}
	}

	banner_sync_category_checkboxes();
	event = new CustomEvent('banner_cookie_banner_data', { detail: banner_cookies });
	document.dispatchEvent(event);

	//if no categories were saved before, we do it now
	if ( banner_get_cookie('saved_categories') === '' ) {
		//for Non optin/optout visitors, and DNT users, we just track the no-warning option
		if ( banner_cookies.consenttype !== 'optin' && banner_cookies.consenttype !== 'optout' ) {
			banner_track_status( 'no_warning' );
		} else if ( banner_cookies.do_not_track ) {
			banner_track_status('do_not_track' );
		}
	}

	banner_set_category_as_body_class();
	//fire cats event, but do not fire a track, as we do this on exit.
	banner_fire_categories_event();
	if (!banner_cookies.do_not_track) {
		if (banner_cookies.consenttype === 'optin') {
			if (banner_cookies.forceEnableStats) {
				banner_enable_category('statistics');
			}
			console.log('opt-in');
			show_cookie_banner();
		} else if (banner_cookies.consenttype === 'optout') {
			console.log('opt-out');
			show_cookie_banner();
		} else {
			console.log('other consent type, no cookie warning');
			//on other consent types, all scripts are enabled by default.
			banner_accept_all();
		}
	} else {
		banner_track_status( 'do_not_track' );
	}
}

/**
 * Get list of services active on the page
 * @returns {*[]}
 */
function banner_get_services_on_page(){
	let services=[];
	document.querySelectorAll('[data-service]').forEach(obj => {
		let service = obj.getAttribute('data-service');
		let category = obj.getAttribute('data-category');
		if ( services.indexOf(service)==-1 ) {
			services.push({
				'category': category,
				'service':service,
			});
		}
	});
	return services;
}

/**
 * Run the actual cookie warning
 *
 * */

window.show_cookie_banner = function () {
	let disableCookiebanner = banner_cookies.disable_cookiebanner;
	//do not show banner when manage consent area on cookie policy is visible
	//when users use only the shortcode, the manage consent container is not active, but the dropdown cookie policy class is.
	//when the banner_cookies shortcode is used, the dropdown cookie policy class is loaded late because it's loaded with javascript.
	let tmpDismissCookiebanner = false;
	if ( document.querySelector('#banner-manage-consent-container') || document.querySelector('.banner-dropdown-cookiepolicy') ) {
		tmpDismissCookiebanner = true;
	}

	var fragment = document.createDocumentFragment();
	let container = document.getElementById('banner-cookiebanner-container');
	if (container) {
		fragment.appendChild(container);
		document.body.prepend(fragment);
	}

	let link = document.createElement("link");
	let pageLinks = banner_cookies.page_links[banner_cookies.region];
	//get correct banner, based on banner_id
	banner_banner = document.querySelector('.banner-cookiebanner.banner-'+banner_cookies.user_banner_id+'.'+banner_cookies.consenttype);
	banner_manage_consent_button = document.querySelector('#banner-manage-consent .banner-manage-consent.manage-consent-'+banner_cookies.user_banner_id);
	let css_file_url = banner_cookies.css_file.replace('{type}', banner_cookies.consenttype ).replace('{banner_id}', banner_cookies.user_banner_id);
	if ( banner_cookies.css_file.indexOf('cookiebanner/css/defaults/banner') != -1 ) {
		console.log('Fallback default css file used. Please re-save banner settings, or check file writing permissions in uploads directory');
	}

	link.href = css_file_url;
	link.type = "text/css";
	link.rel = "stylesheet";
	link.onload = function () {
		if ( !disableCookiebanner ) {
			banner_banner.classList.remove('banner-hidden');
			banner_manage_consent_button.classList.remove('banner-hidden');
		}
	}
	document.getElementsByTagName("head")[0].appendChild(link);
	if ( banner_banner && !disableCookiebanner ) {
		banner_banner.querySelectorAll('.banner-links a:not(.banner-external), .banner-buttons a:not(.banner-external)').forEach(obj => {
			let docElement = obj;
			docElement.classList.add('banner-hidden');
			for (let pageType in pageLinks ) {
				if (pageLinks.hasOwnProperty(pageType) && docElement.classList.contains(pageType)) {
					docElement.setAttribute('href', pageLinks[pageType]['url'] + docElement.getAttribute('data-relative_url'));
					if ( docElement.innerText === '{title}') {
						docElement.innerText = banner_html_decode(pageLinks[pageType]['title']);
					}
					docElement.classList.remove('banner-hidden');
				}
			}
		});

		banner_set_banner_status();
		//we don't use the setBannerStatus function here, as we don't want to save it in a cookie now.
		if ( tmpDismissCookiebanner ) {
			banner_banner.classList.remove('banner-show');
			banner_banner.classList.add('banner-dismissed');
			banner_manage_consent_button.classList.remove('banner-dismissed');
			banner_manage_consent_button.classList.add('banner-show');
		}
	}

	let event = new CustomEvent('banner_cookie_warning_loaded', {detail: banner_cookies.region});
	document.dispatchEvent(event);
}
/**
 * Get the status of the banner: dismissed | show
 * @returns {string}
 */
window.banner_get_banner_status = function (){
	return banner_get_cookie('banner-status');
}

/**
 * Set the banner status so it will be either shown or dismissed, and store it in a cookie.
 * @param status (optional)
 */

window.banner_set_banner_status = function ( status ){
	let prevStatus = banner_get_cookie('banner-status');
	status = typeof status !== 'undefined' ? status : prevStatus;
	if ( status !== prevStatus ) {
		banner_set_cookie( 'banner-status', status );
	}
	if (status.length===0){
		status = 'show';
	}

	if (status==='show') {
		prevStatus = 'dismissed';
	} else {
		prevStatus = 'show';
	}

	if ( banner_banner && status.length>0 ) {
		banner_banner.classList.remove('banner-'+prevStatus);
		banner_banner.classList.add('banner-'+status );
		if ( banner_manage_consent_button ) {
			banner_manage_consent_button.classList.add('banner-'+prevStatus);
			banner_manage_consent_button.classList.remove('banner-'+status);
		}
	}

	if ( banner_banner_container && banner_cookies.soft_cookiewall ) {
		banner_banner_container.classList.remove('banner-'+prevStatus);
		banner_banner_container.classList.add('banner-'+status );
		banner_banner_container.classList.add('banner-soft-cookiewall');
	}
	let event = new CustomEvent('banner_banner_status', { detail: status });
	document.dispatchEvent(event);
	banner_start_clean();
}

/**
 * Check if there is consent for a category or service
 * @param category
 * @returns {boolean}
 */
window.banner_has_consent = function ( category ){
	

	if ( category === 'functional' ) return true;
	var has_consent, value;

	/**
	 * categories
	 */
	value = banner_get_cookie(category);
	if (banner_cookies.consenttype === 'optout' && value === '') {
		//if it's opt out and no cookie is set we should return true
		has_consent = true;
	} else {
		//all other situations, return only true if value is allow
		has_consent = (value === 'allow');
	}

	return has_consent;
}

/**
 * Check if a service has consent
 * @param service
 * @returns {boolean|*}
 */
window.banner_is_service_denied = function ( service ) {
	//Check if it's in the consented services cookie
	var consented_services_json = banner_get_cookie('consented_services');
	var consented_services;
	try {
		consented_services = JSON.parse(consented_services_json);
	} catch (e) {
		consented_services = {};
	}

	if ( !consented_services.hasOwnProperty( service ) ){
		return false;
	} else {
		return !consented_services[service];
	}
}

/**
 * Check if a service has consent
 * @param service
 * @param category
 * @returns {boolean|*}
 */
window.banner_has_service_consent = function ( service, category ) {
	//Check if it's in the consented services cookie
	var consented_services_json = banner_get_cookie('consented_services');
	var consented_services;
	try {
		consented_services = JSON.parse(consented_services_json);
	} catch (e) {
		consented_services = {};
	}

	if ( !consented_services.hasOwnProperty( service ) ){
		//default to the category
		return banner_has_consent( category );
	} else {
		return consented_services[service];
	}
}

/**
 * check if there's at least one service with consent
 * @returns {boolean}
 */
function banner_exists_service_consent(){
	var consented_services_json = banner_get_cookie('consented_services');
	var consented_services;
	try {
		consented_services = JSON.parse(consented_services_json);
		for (const key in consented_services) {
			if ( consented_services.hasOwnProperty(key) ) {
				if (consented_services[key] == true) {
					return true;
				}
			}
		}
	} catch (e) {
		return false;
	}
	return false;
}

/**
 * Set consent for a service
 * @param service
 * @param consented
 */
function banner_set_service_consent( service, consented ){
	var consented_services_json = banner_get_cookie('consented_services');
	var consented_services;
	try {
		consented_services = JSON.parse(consented_services_json);
	} catch (e) {
		consented_services = {};
	}
	consented_services[service] = consented;
	banner_set_cookie('consented_services', JSON.stringify(consented_services) );
	let details = new Object();
	details.service = service;
	details.value = consented;
	details.region = banner_cookies.region;
	//when the status is changed, we may need to fire scripts again, so we reset it.
	banner_all_scripts_hook_fired = false;
	let event = new CustomEvent('banner_status_change_service', { detail: details });
	document.dispatchEvent(event);
}

/**
 * Remove all service consents
 */
function banner_clear_all_service_consents(){
	banner_set_cookie('consented_services', '');
}

/**
 * Get all consented or denied services
 */

function banner_get_all_service_consents(){
	var consented_services_json = banner_get_cookie('consented_services');
	var consented_services;
	try {
		consented_services = JSON.parse(consented_services_json);
	} catch (e) {
		consented_services = {};
	}
	return consented_services;
}
/**
 * Get cookie path
 * @returns {*}
 */
function banner_get_cookie_path(){
	return typeof banner_cookies.cookie_path !== 'undefined' && banner_cookies.cookie_path !== '' ? banner_cookies.cookie_path : '/';
}

/**
 * retrieve domain to set the cookies on
 * @returns {string}
 */
function banner_get_cookie_domain(){
	var domain = '';
	if ( banner_cookies.set_cookies_on_root == 1 && banner_cookies.cookie_domain.length>3){
		domain = banner_cookies.cookie_domain;
	}
	if (domain.indexOf('localhost') !== -1 ) {
		domain = '';
	}
	return domain;
}

/**
 * Set consent for a category
 * @param category
 * @param value
 */
window.banner_set_consent = function (category, value){
	banner_set_accepted_cookie_policy_id();
	var previous_value = banner_get_cookie(category);

	//keep checkboxes in banner and on cookie policy in sync
	//do this before the change check to ensure sync: https://github.com/Really-Simple-Plugins/banner_cookies-gdpr/issues/324
	var checked = value === 'allow';
	document.querySelectorAll('input.banner-'+category).forEach(obj => {
		obj.checked = checked;
	});

	//do not trigger a change event if nothing has changed.
	if ( previous_value === value ) {
		return;
	}

	banner_set_cookie(category, value);
	if ( value === 'allow' ) {
		banner_enable_category(category);
	}

	
	let details = new Object();
	details.category = category;
	details.value = value;
	details.region = banner_cookies.region;
	details.categories = banner_accepted_categories();
	//when the status is changed, we may need to fire scripts again, so we reset it.
	banner_all_scripts_hook_fired = false;
	let event = new CustomEvent('banner_status_change', { detail: details });
	document.dispatchEvent(event);
	if ( category === 'marketing' && value === 'deny' && previous_value === 'allow' ) {
		banner_integrations_revoke();
		//give the code some time to finish, so our track status code can send a signal to the backend.
		setTimeout(function(){
			location.reload()
		}, 500);
	}
}

/**
 * We use ajax to check the consenttype based on region, otherwise caching could prevent the user specific warning
 *
 * */

var banner_user_data = [];
//check if it's already stored
if (typeof (Storage) !== "undefined" && sessionStorage.banner_user_data) {
	banner_user_data = JSON.parse(sessionStorage.banner_user_data);
}

//if not stored yet, load. As features in the user object can be changed on updates, we also check for the version
if ( banner_cookies.geoip == 1 && (banner_user_data.length == 0 || (banner_user_data.version !== banner_cookies.version) || (banner_user_data.banner_version !== banner_cookies.banner_version)) ) {
	var request = new XMLHttpRequest();
	request.open('GET', banner_cookies.url+'banner?'+banner_cookies.locale, true);
	request.setRequestHeader('Content-type', 'application/json');
	request.send();
	request.onload = function() {
		banner_user_data = JSON.parse(request.response);
		sessionStorage.banner_user_data = JSON.stringify(banner_user_data);
		conditionally_show_banner();
	};
} else {
	conditionally_show_banner();
}

/**
 *  when ab testing, or using records of consent, we want to keep track of the unique user id
 */

if ( banner_cookies.store_consent == 1 ) {
	var banner_id_cookie = banner_get_cookie('id');
	var banner_id_session = '';
	var banner_id = '';
	if (typeof (Storage) !== "undefined" && sessionStorage.banner_id) {
		banner_id_session = JSON.parse(sessionStorage.banner_id);
	}

	if ( banner_id_cookie.length == 0 && banner_id_session.length > 0 ) {
		banner_id = banner_id_session;
		banner_set_cookie('id', banner_id );
	}

	if ( banner_id_cookie.length > 0 && banner_id_session.length == 0 ) {
		banner_id = banner_id_cookie;
	}

	if ( typeof (Storage) !== "undefined" ) {
		sessionStorage.banner_id = JSON.stringify(banner_id);
	}
}

// visibilitychange and pagehide work in more browsers hence we check if they are supported and try to use them
document.addEventListener('visibilitychange', function () {
	if ( document.visibilityState === 'hidden' ) {
		banner_track_status_end();
	}
});
window.addEventListener("pagehide", banner_track_status_end, false );
window.addEventListener("beforeunload", banner_track_status_end, false );

function banner_track_status_end(){
	if ( !banner_consent_stored_once ) {
		banner_track_status();
	}
}

/**
 * This creates an API which devs can use to trigger actions in banner_cookies.
 */
document.addEventListener('banner_consent_action', function (e) {
	banner_set_consent( e.detail.category , 'allow' );
	banner_fire_categories_event();
	banner_track_status();
});

/**
 * Accept all categories
 */
window.banner_accept_all = function(){
	banner_clear_all_service_consents();
	for (var key in banner_categories) {
		if ( banner_categories.hasOwnProperty(key) ) {
			banner_set_consent(banner_categories[key], 'allow');
		}
	}
	banner_sync_category_checkboxes();
}

/**
 * Deny all categories, and reload if needed.
 */
window.banner_deny_all = function(){
	for (var key in banner_categories) {
		if ( banner_categories.hasOwnProperty(key) ) {
			banner_set_consent(banner_categories[key], 'deny');
		}
	}
	var consentLevel = banner_highest_accepted_category();
	var reload = false;

	if (consentLevel !== 'functional' || banner_exists_service_consent() ) {
		reload = true;
	}
	

	//has to be after the check if should be reloaded, otherwise that check won't work.
	banner_clear_all_service_consents();
	banner_integrations_revoke();
	banner_fire_categories_event();
	banner_track_status();

	let event = new CustomEvent('banner_revoke', { detail: reload });
	document.dispatchEvent(event);

	//we need to let the iab extension handle the reload, otherwise the consent revoke might not be ready yet.
	if ( !banner_cookies.tcf_active && reload ) {
		location.reload();
	}
}
window.banner_accept_cookie_youtube=function(){

		var tag = document.createElement('script');
		tag.src = "https://www.youtube.com/iframe_api";
		var firstScriptTag = document.getElementsByTagName('script')[0];
		firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

		function getFrameID(id){
		    var elem = document.getElementById(id);
		    if (elem) {
		        if(/^iframe$/i.test(elem.tagName)) return id; //Frame, OK
		        // else: Look for frame
		        var elems = elem.getElementsByTagName("iframe");
		        if (!elems.length) return null; //No iframe found, FAILURE
		        for (var i=0; i<elems.length; i++) {
		           if (/^https?:\/\/(?:www\.)?youtube(?:-nocookie)?\.com(\/|$)/i.test(elems[i].src)) break;
		        }
		        elem = elems[i]; //The only, or the best iFrame
		        if (elem.id) return elem.id; //Existing ID, return it
		        // else: Create a new ID
		        do { //Keep postfixing `-frame` until the ID is unique
		            id += "-frame";
		        } while (document.getElementById(id));
		        elem.id = id;
		        return id;
		    }
		    // If no element, return null.
		    return null;
		}

		// Define YT_ready function.
		var YT_ready = (function() {
		    var onReady_funcs = [], api_isReady = false;
		    /* @param func function     Function to execute on ready
		     * @param func Boolean      If true, all qeued functions are executed
		     * @param b_before Boolean  If true, the func will added to the first
		                                 position in the queue*/
		    return function(func, b_before) {
		        if (func === true) {
		            api_isReady = true;
		            while (onReady_funcs.length) {
		                // Removes the first func from the array, and execute func
		                onReady_funcs.shift()();
		            }
		        } else if (typeof func == "function") {
		            if (api_isReady) func();
		            else onReady_funcs[b_before?"unshift":"push"](func); 
		        }
		    }
		})();
		// This function will be called when the API is fully loaded
		function onYouTubePlayerAPIReady() {YT_ready(true)}
	
}

if (getCookie('accept_marketing') == 1) {
	banner_accept_cookie_youtube();
}
/**
 * For both opt-in and opt-out, clicking banner-accept should result in accepting all categories
 */
banner_add_event('click', '.banner-accept', function(e){
	//window.localStorage.setItem('accept_all', 1);
	e.preventDefault();
   	setCookie("accept_statistics",1);
   	setCookie("accept_marketing",1);
   	banner_set_cookie('accept-matomo',1);
	
	banner_accept_all();
	banner_accept_cookie_youtube();
	banner_set_banner_status('dismissed');
	banner_fire_categories_event();
	banner_track_status();
	window.location.reload();
});



/**
 * Accept a specific service
 */
banner_add_event('click', '.banner-accept-service', function(e){
	let obj = e.target;
	//INPUT should be handled by change event, these are checkboxes on the cookie policy
	let tagName = obj.tagName;
	if ( tagName === 'INPUT' ) {
		return;
	}
	let service = obj.getAttribute('data-service');
	if ( typeof service !== 'undefined' ){
		banner_set_service_consent(service, true);
		banner_enable_category('', 'general');
		banner_enable_category('', service);
	}
	banner_fire_categories_event();
	banner_track_status();
});
/**
 * Accept a specific service
 */
banner_add_event('change', '.banner-accept-service', function(e){
	let obj = e.target;
	let tagName = obj.tagName;
	let service = obj.getAttribute('data-service');
	if ( typeof service !== 'undefined' ) {
		//inputs are from the consent per service option on the cookie policy.
		if ( tagName === 'INPUT' ) {
			if ( obj.checked ){
				banner_set_service_consent(service, true);
				banner_enable_category('', service);
			} else {
				banner_set_service_consent(service, false);
				//give our track status time to finish
				/*setTimeout(function(){ reloadNoAs
					location.reload()
				}, 500); */
			}
			//if not input, it's a placeholder
		} else {
			e.preventDefault();
			banner_set_service_consent(service, true);
			banner_enable_category('', 'general');
			banner_enable_category('', service);

			//give our track status time to finish
			setTimeout(function(){
				location.reload()
			}, 500);
		}
	}
	banner_fire_categories_event();
	banner_track_status();
});



/**
 * On the banner, clicking a category should fire the category only after the save button is clicked.
 *
 */
banner_add_event('click', '.banner-save-preferences', function(e){
	let obj = e.target;
	banner_banner = obj.closest('.banner-cookiebanner');
	for (var key in banner_categories) {
		if ( banner_categories.hasOwnProperty(key) ) {
			var category = banner_categories[key];
			var categoryElement = banner_banner.querySelector('input.banner-' + category);
			if (categoryElement) {

				if (categoryElement.checked) {
					
					banner_set_consent(category, 'allow');
					if (category =='marketing') {
						banner_accept_cookie_youtube();
						 
                         setCookie("accept_marketing",1);
					}
					if (category =='statistics') {
 
                         setCookie("accept_statistics",1);
                        banner_set_cookie('accept-matomo',1);
					}
				
				} else {
					banner_set_consent(category, 'deny');
					if (category =='marketing') {

                         setCookie("accept_marketing",0);
					}
					if (category =='statistics') {
                         setCookie("accept_statistics",0);
                        banner_set_cookie('accept-matomo',0);
					}
				}
			}
		}
	}
	banner_set_banner_status('dismissed');
	banner_fire_categories_event();
	banner_track_status();
	window.location.reload();
});

banner_add_event('click', '.banner-close', function(e){
	banner_set_banner_status('dismissed');
});

banner_add_event('click', '.banner-view-preferences', function(e){
	let obj = e.target;
	banner_banner = obj.closest('.banner-cookiebanner');
	if ( banner_banner.querySelector('.banner-categories').classList.contains('banner-fade-in')) {
		banner_banner.classList.remove('banner-categories-visible');
		banner_banner.querySelector('.banner-categories' ).classList.remove('banner-fade-in');
		banner_banner.querySelector('.banner-view-preferences' ).style.display = 'block';
		banner_banner.querySelector('.banner-save-preferences' ).style.display = 'none';
	} else {
		banner_banner.classList.add('banner-categories-visible');
		banner_banner.querySelector('.banner-categories' ).classList.add('banner-fade-in');
		banner_banner.querySelector('.banner-view-preferences' ).style.display = 'none';
		banner_banner.querySelector('.banner-save-preferences' ).style.display = 'block';
	}
});
/**
 * On the cookie policy, clicking a category should fire the category immediately
 *
 */
banner_add_event('change', '.banner-manage-consent-container .banner-category', function(e){
	for (var key in banner_categories) {
		if ( banner_categories.hasOwnProperty(key) ) {
			var category = banner_categories[key];
			var categoryElement = document.querySelector('.banner-manage-consent-container input.banner-' + category);
			if (categoryElement) {
				if (categoryElement.checked) {
					banner_set_consent(category, 'allow');
					if (category =='marketing') {
						banner_accept_cookie_youtube();
						 
                         setCookie("accept_marketing",1);
					}
					if (category =='statistics') {
 
                         setCookie("accept_statistics",1);
                        banner_set_cookie('accept-matomo',1);
					}
				} else {
					banner_set_consent(category, 'deny');
					if (category =='marketing') {
					          setCookie("accept_marketing",0);
					}
					if (category =='statistics') {
                         setCookie("accept_statistics",0);
                        banner_set_cookie('accept-matomo',0);
					}
				}
				banner_set_banner_status('dismissed');
				banner_fire_categories_event();
				banner_track_status();
			}
		}
	}

});

banner_add_event('click', '.banner-deny', function(e){
	e.preventDefault();
	//window.localStorage.setItem('accept_all', 0);
	setCookie("accept_statistics",0);
   	setCookie("accept_marketing",0);
   	banner_set_cookie('accept-matomo',0);
	banner_set_banner_status('dismissed');
	banner_deny_all();
});

banner_add_event('click', 'button.banner-manage-consent', function(e){
	e.preventDefault();
	banner_set_banner_status('show');
});


/**
 * Fire a event wich passes all consented categories
 * Separated from the actual category consent because we want to bundle it in one event
 */
function banner_fire_categories_event(){
	let details = new Object();
	details.category = banner_highest_accepted_category();
	details.categories = banner_accepted_categories();
	details.region = banner_cookies.region;
	let event = new CustomEvent('banner_fire_categories', { detail: details });
	document.dispatchEvent(event);
}
/**
 * Track the status of current consent
 * @param status
 */

function banner_track_status( status ) {
	var cats = [];
	status = typeof status !== 'undefined' ? status : false;

	let event = new CustomEvent('banner_track_status', { detail: status });
	document.dispatchEvent(event);

	if ( !status ) {
		cats = banner_accepted_categories();
	} else {
		cats = [status];
	}
	banner_set_category_as_body_class();
	var saved_cats, saved_services;
	try {saved_cats = JSON.parse(banner_get_cookie('saved_categories'));} catch (e) {saved_cats = {};}
	try {saved_services = JSON.parse(banner_get_cookie('saved_services'));} catch (e) {saved_services = {};}
	var consented_services = banner_get_all_service_consents();

	//compare current cats with saved cats. When there are no changes, do nothing.
	if (banner_equals(saved_cats, cats) && banner_equals(saved_services, consented_services)) {
		return;
	}

	if ( banner_cookies.store_consent != 1 || banner_is_bot() || banner_is_speedbot() ) {
		return;
	}

	//keep track of the fact that the status was saved at least once
	banner_set_cookie('saved_categories', JSON.stringify(cats));
	banner_set_cookie('saved_services', JSON.stringify(consented_services));
	banner_consent_stored_once = true;
	var request = new XMLHttpRequest();
	request.open('POST', banner_cookies.url+'track', true);
	var data = {
		'consented_categories': cats,
		'consented_services':consented_services,
		'consenttype': window.wp_consent_type,//store the source consenttype, as our banner_cookies.consenttype will not include optinstats.
	};

	request.setRequestHeader('Content-type', 'application/json');
	request.send(JSON.stringify(data));

}

/**
 * Get accepted categories
 *
 * @returns {string}
 */
function banner_accepted_categories() {
	var consentedCategories = banner_categories;
	var consentedTemp = [];

	//because array filter changes keys, we make a temp arr
	for (var key in consentedCategories) {
		if ( consentedCategories.hasOwnProperty(key) ) {
			var category = consentedCategories[key];
			if (banner_has_consent(category)) {
				consentedTemp.push(category);
			}
		}
	}

	consentedCategories = consentedCategories.filter(function(value, index, consentedCategories){
		return banner_in_array(value, consentedTemp);
	});
	return consentedCategories;
}

/**
 * Enable the checkbox for each category which was enabled
 *
 * */

function banner_sync_category_checkboxes() {
	for ( var key in banner_categories ) {
		if ( banner_categories.hasOwnProperty(key) ) {
			var category = banner_categories[key];
			if ( banner_has_consent(category) || category === 'functional' ) {
				document.querySelectorAll('input.banner-' + category).forEach(obj => {
					obj.checked = true;
				});
			} else {
				document.querySelectorAll('input.banner-' + category).forEach(obj => {
					obj.checked = false;
				});
			}
		}

		document.querySelectorAll('.banner-accept-service').forEach(obj => {
			let service = obj.getAttribute('data-service');
			let category = obj.getAttribute('data-category');
			if ( banner_has_service_consent( service, category ) ) {
				obj.checked = true;
			} else if ( banner_is_service_denied( service ) ) {
				obj.checked = false;
			} else {
				//no consent on service level, check if it's category has consent.
				let category = obj.getAttribute('data-category' );
				obj.checked = !!banner_has_consent(category);
			}
		});
	}
}


/**
 *
 * If a policy is accepted, save this in the user policy id
 *
 * */

function banner_set_accepted_cookie_policy_id() {
	banner_set_cookie('policy_id', banner_cookies.current_policy_id);
}

/**
 * For supported integrations, revoke consent
 *
 * */
function banner_integrations_revoke() {
	var cookiesToSet = banner_cookies.set_cookies;
	for (var key in cookiesToSet) {
		if (cookiesToSet.hasOwnProperty(key)) {
			banner_set_cookie(key, cookiesToSet[key][1], false);
			
		}
	}
}

/**
 * For supported integrations, set consent
 *
 * */

function banner_set_integrations_cookies() {
	var cookiesToSet = banner_cookies.set_cookies;
	for (var key in cookiesToSet) {
		if (cookiesToSet.hasOwnProperty(key)) {
			banner_set_cookie(key, cookiesToSet[key][0], false);
		}
	}
}



var banner_cookie_data = [];
function banner_start_clean(){
	if ( banner_cookies.clean_cookies == 1 ) {
		//check if it's already stored
		if (typeof (Storage) !== "undefined" ) {
			banner_cookie_data = JSON.parse( sessionStorage.getItem('banner_cookie_data') );
		}
		//if not stored yet, load. As features in the user object can be changed on updates, we also check for the version
		
			banner_clean();
		
	}
}

function banner_clean(){
	// if the cookie data array is empty, return, nothing to do.
	if ( !banner_cookie_data ) {
		return;
	}
	setTimeout(function(){
		let consent_categories = [
			'preferences',
			'statistics',
			'marketing',
		];
		for (var i in consent_categories) {
			let category = consent_categories[i];
			if ( !banner_has_consent(category) && banner_cookie_data.hasOwnProperty(category) ) {
				let services = banner_cookie_data[category];
				for (var service in services) {
					if ( !banner_has_service_consent(service, category) ) {
						let cookies = services[service];
						for (var j in cookies) {
							let item = cookies[j];
							
							banner_clear_storage(item);
						}
					}
				}
			}
		}

	}, 1000 );
}

/**
 * Clear an item from either session or localstorage
 * @param item
 */
function banner_clear_storage(item){
	if (typeof (Storage) !== "undefined" ) {
		if ( localStorage.item ) {
			localStorage.removeItem(item);
		}
		if ( sessionStorage.item ) {
			sessionStorage.removeItem(item);
		}
	}
}

/**
 * Compare two arrays
 * @param array
 * @returns {boolean}
 */
function banner_equals (array_1, array_2) {
	if ( !Array.isArray(array_1) ) {
		array_1 = Object.keys(array_1);
		array_2 = Object.keys(array_2);
	}
	// if the other array is a falsy value, return
	if (!array_1 || !array_2)
		return false;

	// compare lengths - can save a lot of time
	if (array_1.length != array_2.length)
		return false;

	for (var i = 0, l=array_1.length; i < l; i++) {
		// Check if we have nested arrays
		if (array_1[i] instanceof Array && array_2[i] instanceof Array) {
			// recurse into the nested arrays
			if (!banner_equals(array_1[i], array_2[i]))
				return false;
		}
		else if (array_1[i] != array_2[i]) {
			// Warning - two different object instances will never be equal: {x:20} != {x:20}
			return false;
		}
	}
	return true;
}

$('.return-back').on('click', function (e) {
	$('#cookies__preferences').modal('hide');
	$('#cookies__wrapper').modal('hide');
	$('#cookies__wrapper').modal('show');
})


